import React from "react"
import Layout from "../components/layout"
import Contact from '../components/contact/contact'
import MobileHeader from '../components/header/headerMobile'

const contact = () => {
  return (
  <Layout>
    <MobileHeader/>
    <Contact/>
    </Layout>
  )}

export default contact
