import React from 'react'
import Gbi from "./contactBG"
import Header from '../header/header'
import ContactForm from './contactForm'

const contact = () => {
    return (
       <Gbi>
            <Header/>
            <ContactForm/>
       </Gbi>

    )
}

export default contact
